import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Blog from "../components/Blog";
import "../assets/scss/crypto.scss";
import Hero from "../components/crypto/Hero";
import SectionTwo from "../components/crypto/SectionTwo";
import SectionThree from "../components/crypto/SectionThree";
import AppDownloadSection from "../components/AppDownloadSection";

const Crypto = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="crypto-page">
      <Helmet>
        <title>Crypto - Bitscard</title>
        <meta name="description" content="Manage your crypto assets securely and efficiently. Explore easy and fast crypto banking services tailored for you." />
      </Helmet>
      <Hero />
      <SectionTwo />
      <SectionThree />
      <AppDownloadSection
        bgColor="#A875CF"
        contentPosition="left"
        firstContentWord="send &"
        content="spend, bank & trade crypto with ease "
      />
      <Blog />
    </div>
  );
};

export default Crypto;
