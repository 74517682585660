import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Blog from "../components/Blog";
import AppDownloadSection from "../components/AppDownloadSection";
import SectionThree from "../components/giftcard/SectionThree";
import "../assets/scss/giftcard.scss";
import SectionTwo from "../components/giftcard/SectionTwo";
import Hero from "../components/giftcard/Hero";

const Giftcard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="giftcard-page">
      <Helmet>
        <title>Giftcard - Your Instant Cash Solution</title>
        <meta name="description" content="Trade your gift cards for instant cash with our easy and secure platform. Start today!" />
        <meta name="keywords" content="gift cards, instant cash, trading gift cards, secure platform" />
        <meta property="og:title" content="Giftcard - Your Instant Cash Solution" />
        <meta property="og:description" content="Trade your gift cards for instant cash with our easy and secure platform. Start today!" />
        <meta property="og:image" content="https://www.bitscard.app/logo.png" />
        <meta property="og:url" content="https://www.bitscard.app/" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Hero />
      <SectionTwo />
      <SectionThree />
      <AppDownloadSection
        withBorderRadius={true}
        bgColor="#5FC88F"
        contentPosition="right"
        firstContentWord="Start"
        content="trading your giftcards for instant cash"
      />
      <Blog />
    </div>
  );
};

export default Giftcard;
