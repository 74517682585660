import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/home/hero";
import "../assets/scss/home.scss";
import SectionOne from "../components/home/SectionOne";
import SectionTwo from "../components/home/SectionTwo";
import SectionThree from "../components/home/SectionThree";
import SectionFour from "../components/home/SectionFour";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <Helmet>
        <title>Home - Welcome to Our Platform | Bitscard</title>
        <meta
          name="description"
          content="Bitscard lets you easily save, send, spend, and trade crypto and gift cards. Join our platform for seamless and secure transactions."
        />
        <meta name="keywords" content="Finance, Technology, Innovation, Services, Home" />
        <meta property="og:title" content="Home - Welcome to Our Platform | Bitscard" />
        <meta property="og:description" content="Bitscard lets you easily save, send, spend, and trade crypto and gift cards. Join our platform for seamless and secure transactions." />
        <meta property="og:image" content="https://www.bitscard.app/logo.png" />
        <meta property="og:url" content="https://www.bitscard.app/" />
      </Helmet>

      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </div>
  );
};

export default Home;
